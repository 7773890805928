import React from "react";
import styled from "@emotion/styled";
import { Spring } from "react-spring/renderprops";
import { withGesture } from "react-with-gesture";

import { mq } from "../utilities/styles";

const SlideContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
`;

const SlideCard = styled.div`
  position: relative;
  width: 450px;
  height: 222px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  border-radius: 8px;
  overflow: hidden;
  border: 1.3px solid white;
  ${mq[2]} {
    width: 300px;
    height: 148px;
  }
`;

function Slide({
  content,
  offsetRadius,
  index,
  animationConfig,
  moveSlide,
  delta,
  down,
  up
}) {
  const offsetFromMiddle = index - offsetRadius;
  const totalPresentables = 2 * offsetRadius + 1;

  const translateYoffset =
    50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
  let translateY = -50;

  if (offsetRadius !== 0) {
    if (index === 0) {
      translateY = 0;
    } else if (index === totalPresentables - 1) {
      translateY = -100;
    }
  }

  if (offsetFromMiddle === 0 && down) {
    translateY += delta[1] / (offsetRadius + 1);
    if (translateY > -40) {
      moveSlide(-1);
    }
    if (translateY < -100) {
      moveSlide(1);
    }
  }
  if (offsetFromMiddle > 0) {
    translateY += translateYoffset;
  } else if (offsetFromMiddle < 0) {
    translateY -= translateYoffset;
  }

  return (
    <Spring to={styles[index]} config={animationConfig}>
      {(style) => (
        <SlideContainer
          style={{
            ...style,
            zIndex: Math.abs(Math.abs(offsetFromMiddle) - 10)
          }}
        >
          <SlideCard onClick={() => moveSlide(offsetFromMiddle)}>
            {content}
          </SlideCard>
        </SlideContainer>
      )}
    </Spring>
  );
}

const styles = {
  0: {
    transform: "translateX(0%) translateY(-41.7%) scale(0.8)",
    top: "22%",
    filter: "brightness(0%)",
    cursor: "auto"
  },
  1: {
    transform: "translateX(0%) translateY(-80%) scale(0.85)",
    top: "43%",
    filter: "brightness(20%)",
    cursor: "pointer"
  },
  2: {
    transform: "translateX(0%) translateY(-70%) scale(0.9)",
    top: "44%",
    filter: "brightness(40%)",
    cursor: "pointer"
  },
  3: {
    transform: "translateX(0%) translateY(-60%) scale(0.95)",
    top: "47%",
    filter: "brightness(70%)",
    cursor: "pointer"
  },
  4: {
    transform: "translateX(0%) translateY(-50%) scale(1)",
    top: "50%",
    filter: "brightness(100%)",
    cursor: "auto"
  },
  5: {
    transform: "translateX(0%) translateY(-40%) scale(0.95)",
    top: "52%",
    filter: "brightness(70%)",
    cursor: "pointer"
  },
  6: {
    transform: "translateX(0%) translateY(-30%) scale(0.9)",
    top: "54%",
    filter: "brightness(40%)",
    cursor: "pointer"
  },
  7: {
    transform: "translateX(0%) translateY(-20%) scale(0.85)",
    top: "55%",
    filter: "brightness(20%)",
    cursor: "pointer"
  },
  8: {
    transform: "translateX(0%) translateY(-60%) scale(0.8)",
    top: "74%",
    filter: "brightness(0%)",
    cursor: "auto"
  }
};
export default withGesture()(Slide);
