import React, {Component} from 'react'
import styled from "@emotion/styled";

import VerticalCarousel from "./VerticalCarousel";
import { config } from "react-spring";

import Img110 from '../assets/test110.gif'
import Img169 from '../assets/test169.gif'
import Img304 from '../assets/test304.gif'
import Img366 from '../assets/test366.gif'
import Img381 from '../assets/test381.gif'
import Img403 from '../assets/test403.gif'
import Img439 from '../assets/test439.gif'
import Img751 from '../assets/test751.gif'
import Img1017 from '../assets/test1017.gif'

const SlideImg = styled.img`
  width: 102%;
  height: auto;
`

const slides = [
  {
    key: 0,
    content: (
      <SlideImg
        src={Img110}
        alt="card"
      />
    )
  },
  {
    key: 1,
    content: (
      <SlideImg
        src={Img169}
        alt="card"
      />
    )
  },
  {
    key: 2,
    content: (
      <SlideImg
        src={Img439}
        alt="card"
      />
    )
  },
  {
    key: 3,
    content: (
      <SlideImg
        src={Img304}
        alt="card"
      />
    )
  },
  {
    key: 4,
    content: (
      <SlideImg
        src={Img366}
        alt="card"
      />
    )
  },
  {
    key: 5,
    content: (
      <SlideImg
        src={Img381}
        alt="card"
      />
    )
  },
  {
    key: 6,
    content: (
      <SlideImg
        src={Img403}
        alt="card"
      />
    )
  },
  {
    key: 7,
    content: (
      <SlideImg
        src={Img1017}
        alt="card"
      />
    )
  },
  {
    key: 8,
    content: (
      <SlideImg
        src={Img751}
        alt="card"
      />
    )
  }
];

class Cards extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 4,
    config: config.gentle
  };

  render() {
    return (
      <VerticalCarousel
        slides={slides}
        offsetRadius={this.state.offsetRadius}
        animationConfig={this.state.config}
      />
  );
  }
}

export default Cards